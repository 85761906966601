<template>
  <div>
    <!-- 官方账号 -->
    <div class="official">
      <div class="official-txt">{{ $t("footer.official") }}</div>
      <div class="official-hz">
        <div
          v-for="(item, index) in list"
          :key="index"
          class="official-xhz"
          @click="goPage(item)"
        >
          <img :class="item.sty" :src="item.img" alt="" />
          <div class="official-xhz-txt">
            {{ $i18n.locale == "zh" ? item.zh : item.en }}
          </div>
        </div>
      </div>
    </div>
    <!-- 联系我们 -->
    <div class="footer">
      <!-- <div class="tc p20"><a href="#" class="backBtn">置顶</a></div> -->
      <!-- ${$i18n.locale == 'zh' ? '' : 'E'} -->
      <div class="footer-top">
        <img
          class="footer-top-img"
          :src="require(`@/assets/flogo.png`)"
          alt=""
        />
        <div class="footer-hz">
          <div class="f14 c_c6" v-html="$t('footer.sponsor')"></div>
          <img class="footer-hz-img" src="@/assets/home/home-3.png" alt="" />
        </div>
      </div>
      <div class="mt-20">
        <div class="f14 c_c6">{{ $t("footer.name") }}</div>
        <div class="footerRow flex" style="margin-top: 9px">
          <img
            src="@/assets/map.png"
            style="width: 12px; height: 13px; margin-top: 3px"
          />
          <span class="">{{ $t("footer.site") }}</span>
        </div>
         <div class="footerRow" style="margin-top: 9px">
            <img src="@/assets/email.png" width="12" />suzhousco@foxmail.com
          </div>
          <div class="footerRow" style="margin: 9px 0 20px 0">
            <img src="@/assets/tel.png" width="12" />0512- 69589013 ({{ $t("footer.time")}} 10:00-16:30)
          </div>
      </div>
      <div class="copyright">
        {{ $t("footer.company") }}
        <!-- ©2017苏州民族管弦乐团有限公司. all rights reserved. 苏ICP 备17070606号-1 -->
      </div>
      <div class="copyright">{{ $t("footer.record") }}</div>
      <div class="copyright">
        {{ $t("footer.accretion") }}
      </div>
      <div class="copyright">
        {{ $t("footer.network") }}
      </div>
    </div>
    <el-dialog title="" :visible.sync="centerDialogVisible" width="70%" center>
      <div class="dialog-hz">
        <img class="dialog-img" src="@/assets/home/dyQRCode.png" alt="" />
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      centerDialogVisible: false,
      list: [
        {
          id: 0,
          img: require("../assets/home/official-1.png"),
          zh: "抖音",
          en: "TikTok",
          url: "https://v.douyin.com/dAqyKvt/",
          sty: "official-img1",
          type: false,
        },
        {
          id: 1,
          img: require("../assets/home/official-2.png"),
          zh: "哔哩哔哩",
          en: "Bilibili",
          url: "https://b23.tv/5qd74H",
          sty: "official-img1",
          type: false,
        },
        {
          id: 2,
          img: require("../assets/home/official-3.png"),
          zh: "微博",
          en: "Microblog",
          url: "https://weibo.com/u/6342383305",
          sty: "official-img2",
          type: false,
        },
        {
          id: 3,
          img: require("../assets/home/official-4.png"),
          zh: "微信",
          en: "WeChat",
          url: "",
          sty: "official-img3",
          type: true,
        },
        {
          id: 4,
          img: require("../assets/home/official-5.png"),
          zh: "YouTube",
          en: "YouTube",
          url: "https://www.youtube.com/c/suzhouco",
          sty: "official-img4",
          type: false,
        },
        {
          id: 5,
          img: require("../assets/home/official-6.png"),
          zh: "Facebook",
          en: "Facebook",
          url: "https://www.facebook.com/suzhouco",
          sty: "official-img1",
          type: false,
        },
        {
          id: 6,
          img: require("../assets/home/official-7.png"),
          zh: "TikTok",
          en: "TikTok",
          url: "https://www.tiktok.com/@suzhouchineseorchestra/video/7088207782054300970?is_from_webapp=1&sender_device=pc&web_id=7079018117335434753",
          sty: "official-img1",
          type: false,
        },
        {
          id: 7,
          img: require("../assets/home/official-8.png"),
          zh: "Instagram",
          en: "Instagram",
          url: "https://www.instagram.com/suzhouchineseorchestra/",
          sty: "official-img1",
          type: false,
        },
      ],
    };
  },
  methods: {
    goPage(val) {
      if (val.type) {
        this.centerDialogVisible = true;
      } else {
        window.open(val.url, "_self");
      }
    },
  },
};
</script>

<style scoped>
.dialog-hz {
  width: 100%;
  text-align: center;
}
.dialog-img {
  width: 200px;
  height: 200px;
}
.official {
  width: 100%;
  height: 138px;
  background-color: #e8eef0;
  overflow: hidden;
}
.official-txt {
  font-size: 13px;
  color: #666666;
  margin-top: 12px;
  margin-left: 18px;
}
.official-hz {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
}
.official-xhz {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 25%;
  height: 35px;
  margin-top: 11px;
  justify-content: space-between;
}
.official-xhz-txt {
  color: #b3b3b3;
  font-size: 11px;
}
.official-img1 {
  width: 20px;
  height: 20px;
}
.official-img2 {
  width: 23px;
  height: 20px;
}
.official-img3 {
  width: 25px;
  height: 20px;
}
.official-img4 {
  width: 28px;
  height: 20px;
}

.official-span2 {
  color: #4d4d4d;
  font-size: 12px;
}
.footer-top {
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
}
.footer-top-img {
  width: 104px;
  height: 68px;
  /* margin-right: 30px; */
}
.footer-hz {
  text-align: center;
}
.footer-hz-img {
  width: 64px;
  height: 44px;
  margin-top: 9px;
}
.footer-xhz {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
</style>
